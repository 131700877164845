<template>
  <div class="hackathon">
    <section :class="visual_intro">
      <div class="hackathon-wrapper">
        <div class="hackathon-bg-wrapper">
          <div class="hackathon-bg"
          :style="{ backgroundImage: 'url(' + requireAssetsImg('hackathon/hackathon.png') + ')' }"
          >
          </div>
          <div class="companyBtn company1Btn">
            <a href="https://xangle.io/" target="_blank"></a>
          </div>
          <div class="companyBtn company2Btn">
            <a href="https://www.krustuniverse.com/" target="_blank"></a>
          </div>
          <div class="companyBtn company3Btn">
            <a href="https://myabcwallet.io/ko/" target="_blank"></a>
          </div>
          <div class="companyBtn company4Btn">
            <a href="https://www.hashed.com/" target="_blank"></a>
          </div>
          <div class="companyBtn company5Btn">
            <a href="https://www.coindeskkorea.com/" target="_blank"></a>
          </div>
          <div class="dreamplusBtn">
            <a href="https://www.dreamplus.asia/" target="_blank"></a>
          </div>
          <div class="mailtoBtn">
            <a href="mailto:dreamplus.hkt@gmail.com" target="_blank"></a>
          </div>
          <div class="applyBtn">
            <button @click="applyTo()" title="지원하기"></button>
          </div>
        </div>
        <div class="hackathon-main">
          <div class="hackathon1">
          </div>
          <div class="hackathon2">
          </div>
          <div class="hackathon3">
          </div>
          <div class="hackathon4">
          </div>
          <div class="hackathon5">
          </div>
          <div class="hackathon6">
          </div>
          <div class="hackathon7">
          </div>
        </div>
      </div>
      <!-- <div class="block_top bg_img"
        :style="{ backgroundImage: 'url(' + requireAssetsImg('hackathon/top_bg.jpg') + ')' }">
        <div class="cont_wrap">
          <img :src="requireAssetsImg('hackathon/top_front.png')" alt="Dream Plus">
        </div>
      </div>
      <div class="blind">
        <h1>Blockchain Hackathon</h1>
        <p>블록체인 업계 현직 개발자 멘토와 함께 팀을 이뤄 2박3일 간 진행되는
           새로운 방식의 해커톤! 드림플러스 블록체인 해커톤에 여러분을 초대합니다.</p>
        <h2>Activity Benefit</h2>
        <sub>해커톤 참가자에게는 아래 혜택이 제공됩니다.</sub>
        <dl>
          <dt>블록체인 네트워크 참여</dt>
          <dd>블록체인 현직자와의 팀 활동 및 네트워킹 기회</dd>
          <dt>IPAD MINI6</dt>
          <dd>블록체인 현직자와의 팀 활동 및 네트워킹 기회</dd>
          <dt>MacBook Pro/Air</dt>
          <dd>해커톤 최우수 및 우수상 수상자 전원에게 맥북 프로/에어 상품수여</dd>
        </dl>
      </div>
      <div class="block1 bg_img"
        :style="{ backgroundImage: 'url(' + requireAssetsImg('hackathon/block1_bg.jpg') + ')' }">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('hackathon/block1_txt.jpg')" alt="Blockchain Hackathon 주제"></h2>
        </div>
        <div class="blind">
          <p>이더리움, 이오스, 파트너사 플랫폼 등을 활용한 자유 주제의 블록체인 웹/앱 서비스 프로토 타입 개발</p>
          <small>NFT, 금융 관련 비즈니스에 대한 기획 요소 반영 시 가점 부여</small>
        </div>
      </div>
      <div class="block2 bg_navy">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('hackathon/block2_txt.png')" alt="모집 대상"></h2>
        </div>
        <div class="blind">
          <p>2인으로 구성된 팀 단위 지원이 가능합니다.</p>
          <small>대학생, 일반인 모두 참여 가능하며 각 팀은 최소 1인 이상의
            개발자를 포함하고 있어야 합니다. *총 8팀(16인) 선발 예정</small>
          <dl>
            <dt>Developer</dt>
            <dd>블록체인을 활용한 FE/BE 시스템 개발의 역량을 갖고 있는 분</dd>
            <dt>Biz Planner</dt>
            <dd>블록체인의 사상을 이해하고 있으며, 이를 활용한 기획이 가능한 분</dd>
          </dl>
        </div>
      </div>
      <div class="block3">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('hackathon/block3_txt.png')" alt="모집안내"></h2>
        </div>
        <div class="blind">
          <dl>
            <dt>모집기간</dt>
            <dd>2021년11월17일(WED) - 12월3일(FRI) 11PM</dd>
            <dt>진행과정</dt>
            <dd>11.17 ~ 12.3 모집접수</dd>
            <dd>11.17 ~ 12.9 서류심사</dd>
            <dd>12.10 4PM 최좀 참가자 발표</dd>
            <dd>12.4 참가자 OT 및 담당 멘토 매칭</dd>
            <dd>12.17 ~ 19 해커톤 진행</dd>
            <dd>12.19 결과 발표 및 시상</dd>
            <dt>참가자발표</dt>
            <dd>최종 선발된 팀에 한하여 지원 시 작성해 주신 이메일 및 유선으로 개별 안내
              <small>
                *선발되지 않은 지원자에 대해서는 별도의 안내를 드리지 않습니다.
              </small>
            </dd>
            <dt>지원방법</dt>
            <dd>우측 '공모 지원하기' 버튼을 통한 온리인 지원
              <small>제출된 기획서 및 제안서는 반환되지 않습니다.</small>
            </dd>
          </dl>
        </div>
      </div>
      <div class="block4 bg_img"
        :style="{ backgroundImage: 'url(' + requireAssetsImg('hackathon/block1_bg.jpg') + ')' }">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('hackathon/block4_txt.png')" alt="심사 및 평가 방법"></h2>
        </div>
        <div class="blind">
          <dl>
            <dt>심사위원</dt>
            <dd>드림플러스 블록체인 해커톤 파트너사의 전문가로 구성된 멘토진</dd>
            <dt>서류 평가 기준</dt>
            <dd>아이디어 또는 서비스 모델의 창의성, 구현, 가능성, 기대효과</dd>
            <dt>본선 평가 기준</dt>
            <dd>서비스 모델의 구현 완성도, 비즈니스 가능성, 블록체인 적용 적합성 평가</dd>
          </dl>
        </div>
      </div> -->
      <!-- <div class="block5 bg_navy">
        <div class="cont_wrap">
          <div v-if="accApplyPgmMngList.length>0">
            <router-link v-if="!isApplyCompleted"
            :to="{ name: 'ProgramsApplyStep1',
            params: {
              accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
            } }"
            >
            <img :src="requireAssetsImg('hackathon/block5_btn.png')"
              :alt="$t('content.programs.Application.btn.txt01')">
            </router-link>
           <router-link v-else
             :to="{ name: 'ProgramsMyApplyList',
             params: {
               accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
             } }"
        ><img :src="requireAssetsImg('hackathon/block5_btn.png')"
              :alt="$t('content.programs.Application.btn.txt01')"></router-link>
          </div>
        </div>
      </div> -->
      <!-- <div class="block6">
        <div class="left">
          <div class="cont_wrap">
            <h2><img :src="requireAssetsImg('hackathon/block6_left_text.png')"
              alt="최종 선발팀 대상 블록체인 해커톤 본선(오프라인)"></h2>
          </div>
        </div>
        <div class="right">
          <div class="cont_wrap">
            <h3><img :src="requireAssetsImg('hackathon/block6_right_text.png')"
             alt="장소 및 일정"></h3>
          </div>
          <div class="blind">
            <dl>
              <dt>장소</dt>
              <dt>한화생명 드림파크</dt>
              <dd>경기 용인시 기흥구 이현로30번길 45-4</dd>
              <dt>일정</dt>
              <dd>2021.12.17(fri) 5PM ~ 12.19(sun) 2PM</dd>
              <dd>2박3일 전체 일정에 대한 교통 및 숙식이 제공됩니다.</dd>
            </dl>
          </div>
        </div>
      </div> -->
      <!-- <div class="block7">
        <div class="cont_wrap">
          <img :src="requireAssetsImg('hackathon/block7_logo.png')" alt="주최 - 드림플러스">
        </div>
      </div>
      <div class="block8 bg_navy">
        <div class="cont_wrap">
          <strong>문의</strong>
          <a href="mailto:dreamplus.hkt@gmail.com" target="_blank" >dreamplus.hkt@gmail.com</a>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
// import scheduleManage from '../../../../innovationhub_admin/src/components/portal/content/scheduleManage.vue';
/**
 * Programs > Academy & Internship
 *
 */
export default {
  // components: { scheduleManage },
  name: 'Hackathon',
  mixins: [
    mixinHelperUtils,
    StaggerTransition,
  ],
  props: {
    accApplyPgmMngList: {
      type: Array,
      default: () => [],
    },
    isApplyCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visual_intro: 'visual_intro',
    };
  },
  mounted() {
    if (window.location.pathname === '/programs/hackathon'
      && (sessionStorage.getItem('hackerton') === undefined || sessionStorage.getItem('hackerton') === null)) {
      this.visual_intro = 'novisual_intro';
    }
  },
  methods: {
    applyTo() {
      if (window.gtag) {
        const userInfo = this.$store.getters.getUserInfo();
        if (userInfo) {
          window.gtag('event', 'First_Apply_login_221014_btn2', {
            event_category: 'apply',
            event_label: 'First_Apply_login_221014',
          });
        } else {
          window.gtag('event', 'First_Apply_Nologin_221014_btn2', {
            event_category: 'apply',
            event_label: 'First_Apply_Nologin_221014',
          });
        }
      }
      // this.$router.push({
      //   name: 'ProgramsApplyStep1',
      //   params: {
      //     accPgmMngNoString: `${this.accApplyPgmMngList[0].accPgmMngNo}`,
      //   },
      // });
      window.location.href = 'http://dreamplus.asia/programs/application/14/apply/step1';
    },
  },
};
</script>
<style>
.hackathon img{ max-width: 100%}
.hackathon .cont_wrap { width: 1312px; margin:0 auto; text-align: center;}
.hackathon .bg_img{ background-size: 100% 100%;}
.hackathon .bg_navy{ background-color: #0A141E;}
.hackathon .visual_intro, .hackathon .novisual_intro{ height: auto;}
.hackathon .block5{padding: 40px 0;}
.hackathon .block5 a{ display: inline-block;}
.hackathon .left{ overflow: hidden; float: left; width: 50%;
 text-align: left;background-color: #4753F0; height: inherit;}
.hackathon .right{ float: left; width: 50%; text-align: left;
height: inherit; box-sizing: border-box; background-color: #14DC96; }
.hackathon .left .cont_wrap{
  float: right;
}
.hackathon .block6 .cont_wrap{height: 330px; width: 656px; margin: 0;}
.hackathon .block6{ overflow: hidden; }
.hackathon .block8{ padding: 30px 0; font-size: 18px; color: #fff;}
.style_hackathon footer{ margin-top: 0;}
.style_hackathon .scroll-top{ display: none;}
.wrap.hackathon footer{margin-top: 0;}
.wrap.hackathon .scroll-top.upper{bottom: 280px;}
.hackathon-wrapper {position: relative;}
.hackathon-bg-wrapper {
  position: absolute;
  width: 100%;
  top:0;
}
.hackathon-bg {
  background-image: url(/img/hackathon/hackathon.jpg);
  background-repeat: no-repeat;
  height: calc((100vh - 68px) * (5860 / 974));
  width: 1312px;
  width: calc((100vh - 68px) * (1312 / 974));
  background-size: auto 100%;
  margin: 0 auto;
}

.hackathon-main {
  width: 100%;
}

.hackathon1 {
  background-color: #eb5e0c;
  height: 738px;
  height: calc((100vh - 68px) * (738 / 974));
}
.hackathon2 {
  background-color: #000000;
  height: 236px;
  height: calc((100vh - 68px) * (236 / 974));
}
.hackathon3 {
  background-color: #ffffff;
  height: 650px;
  height: calc((100vh - 68px) * (650 / 974));
}
.hackathon4 {
  background-color: #e8e8e8;
  height: 607px;
  height: calc((100vh - 68px) * (607 / 974));
}
.hackathon5 {
  background-color: #eb5e0c;
  height: 1476px;
  height: calc((100vh - 68px) * (1476 / 974));
}
.hackathon6 {
  background-color: #e8e8e8;
  height: 1919px;
  height: calc((100vh - 68px) * (1919 / 974));
}
.hackathon7 {
  background-color: #000000;
  height: 234px;
  height: calc((100vh - 68px) * (234 / 974));
}

.applyBtn {
  position: absolute;
  bottom: 338px;
  bottom: calc((100vh - 68px) * (338 / 974));
  width: 100%;
  height: 100px;
  height: calc((100vh - 68px) * (100 / 974));
}
.applyBtn > button {
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 287px;
  width: calc((100vh - 68px) * (287 / 974));
  transform: translatex(-15px);
}
.mailtoBtn {
  position: absolute;
  bottom: 60px;
  bottom: calc((100vh - 68px) * (60 / 974));
  width: 100%;
  height: 25px;
  height: calc((100vh - 68px) * (25 / 974));
}
.mailtoBtn a{
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 245px;
  width: calc((100vh - 68px) * (245 / 974));
}
.dreamplusBtn {
  position: absolute;
  bottom: 118px;
  bottom: calc((100vh - 68px) * (118 / 974));
  width: 100%;
  height: 56px;
  height: calc((100vh - 68px) * (56 / 974));
}
.dreamplusBtn a {
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 157px;
  width: calc((100vh - 68px) * (157 / 974));
}
.companyBtn {
  position: absolute;
  width: 100%;
  height: 165px;
  height: calc((100vh - 68px) * (165 / 974));
}
.company1Btn {
  /* bottom: 1830px; */
  bottom: calc((100vh - 68px) * (1830 / 974))
}
.companyBtn a{
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 980px;
  width: calc((100vh - 68px) * (980 / 974));
}
.company2Btn {
  /* bottom: 1621px; */
  bottom: calc((100vh - 68px) * (1621 / 974));
}
.company3Btn {
  bottom: 1412px;
  bottom: calc((100vh - 68px) * (1412 / 974));
}
.company4Btn {
  bottom: 1202px;
  bottom: calc((100vh - 68px) * (1202 / 974));
}
.company5Btn {
  bottom: 993px;
  bottom: calc((100vh - 68px) * (993 / 974));
}
.floating-wrap.apply .btn-floating  {
  background: #007cd7 !important;
}
.floating-wrap.apply .btn-floating:hover {
  background: #246a9e !important;
}
.floating-wrap.apply .rotate-circle .caption {
  fill: #007cd7 !important;
}
</style>
